@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";



@layer base {

	input[type='number']::-webkit-outer-spin-button,
	input[type='number']::-webkit-inner-spin-button,
	input[type='number'] {
		-webkit-appearance: none;
		-moz-appearance: textfield !important;
	}
}



@layer components {

	.scrollbar-styled {
		scrollbar-gutter: stable;
	}

	.scrollbar-styled::-webkit-scrollbar {
		@apply w-2
	}

	.scrollbar-styled::-webkit-scrollbar-track {
		@apply bg-transparent
	}

	.scrollbar-styled:hover::-webkit-scrollbar-thumb {
		@apply bg-turquoise/10 hover:bg-turquoise/20 rounded-full
	}
	


	.scrollbar-left {
		direction: rtl;
	}

	.scrollbar-left * {
		direction: ltr;
	}
}